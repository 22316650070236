import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"


const Page = ({data}) => {
  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
    {
      pathname: '/experience',
      crumbLabel: '研究成果の普及'
    },
  ]

  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 

  return(
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title={t("見学お申込み")}  lang={lang} />
      <ContentLayout lang={lang} onlyjatext={true}>
        <h1>見学お申込み</h1>
        <ul className="anchorlink">
          <li>
            <AnchorLink anchor="#anchor01">申込方法</AnchorLink>
          </li>
          <li>
            <AnchorLink anchor="#anchor02">遵守事項</AnchorLink>
          </li>
        </ul>


        <p>事前予約が必要です。以下のいずれかの方法でお申し込みください。事前予約がない方の施設見学はできません。 </p>
        
        <h2 id="anchor01">申込方法</h2>
        <p>
          予約は見学予定日の1ヶ月以上前にお申し込みください。<br/>
          見学は月曜日、水曜日～金曜日（毎週火曜日、土日祝祭日、年末年始を除く）の10:00 - 16:00までとなります。
        </p>
        <h3>つくばサイエンスツアーオフィス</h3>
        <p>下記の連絡先から事前予約をしてください。</p>
        <p className="contact-box">
          <span className="name">つくばサイエンスツアーオフィス連絡先（代表）</span>
          <span className="main">029-863-6868</span>
          <span className="sub">平日 9:00 - 17:00 </span>
          <External href="https://www.i-step.org/tour/mail-form.html" btn="2">
            お問い合わせ(サイエンスツアーオフィス)
          </External>
        </p>
        
        <p className="annotation normal">
          つくばサイエンスツアーオフィス（一般財団法人茨城県科学振興財団）は、つくば市内の研究機関等の見学紹介、コースの企画・提案を無料で行っている一般財団法人です。
        </p>

        <h3>理化学研究所筑波キャンパスへ直接お申し込み</h3>
        <p>下記の連絡先から事前予約をしてください。</p>
        <p className="contact-box">
          <span className="name">連絡先（代表）</span>
          <span className="main">029-836-9111</span>
        </p>

        <DataList>
          <DataListRow title="仮予約票">
            <Assets 
              data={data}
              name="tmpReserve.xlsx"
              >
              仮予約票
            </Assets>
            </DataListRow>
            <DataListRow title="仮予約票送付先">
              kengaku-tsukuba [at] ml.riken.jp
            </DataListRow>
        </DataList>
        
        <p>メールアドレスの[at]は@に置き換えてください。</p>
        

        <h2 id="anchor02">遵守事項</h2>
        <p className="txt-center caution">
          <b>施設見学を希望される皆様へ</b><br />
        </p>
        <p>
          見学者の皆さまに安心して見学いただくために、以下のように施設見学に関する事項を定めました。 施設見学にあたりましては、本事項を遵守いただきますよう、皆さまのご協力をよろしくお願いいたします。
        </p>

        <h3>見学内容</h3>
        <p>当面の間、受け入れ人数と時間制限を設けた上での見学となります。</p>
        <DataList>
          <DataListRow title="見学受け入れ人数">10名以上24名以内（同行者含む）</DataListRow>
          <DataListRow title="見学時間">60分</DataListRow>
        </DataList>
        
        <h3>施設見学前の準備（見学代表者の方へ）</h3>
        <p className="mark-txt">
          <span className="mark">（１）</span>
          次に該当する場合は見学ができませんので、見学代表者は見学者全員への周知、確認をお願いします。
        </p>
        <div className="mark-txt-content">
          <ul className="numlist">
            <li><span className="num">1.</span>風邪の症状（発熱や咳、倦怠感など体調の不調、味覚、嗅覚の不調）のある方</li>
            <li><span className="num">2.</span>解熱後、解熱剤の服用なしで 72 時間経過していない方</li>
          </ul>
        </div>
        
        <p className="mark-txt">
          <span className="mark">（２）</span>
          <Assets 
            data={data}
            name="vstBRC_List.xlsx"
            >
            [別紙]理化学研究所バイオリソース研究センター施設見学者名簿
          </Assets>
          
          に見学者全員の氏名・住所・電話番号を記載の上、見学日１週間前までに、下記アドレスにお送りください。
        </p>
        <div className="mark-txt-content">
          <p>
            ※ 見学代表者は全項目を記載してください。中高生の生徒は氏名のみを記載してください。<br />
          </p>
          <DataList>
            <DataListRow title="見学者名簿送付先">
              kengaku-tsukuba [at] ml.riken.jp<br />
              総務課見学担当宛
            </DataListRow>
          </DataList>

          <p>
            メールアドレスの[at]は@に置き換えてください。<br />
            見学者名簿の保存期間は1ヶ月とし、保存期間終了後は廃棄処分いたします。
          </p>
        </div>

       
        <h3>施設見学当日の注意事項</h3>
        <p className="mark-txt">
          <span className="mark">（１）</span>
          以下のことを遵守してください。
        </p>
        <div className="mark-txt-content">
          <ul className="numlist">
            <li><span className="num">1.</span>37.5℃以上の発熱のある方は見学をお控えください。</li>
            <li><span className="num">2.</span>マスクの着用は任意です。</li>
            <li><span className="num">3.</span>手洗い、手指の消毒にご協力ください。</li>
            <li><span className="num">4.</span>飲食は指定の場所で願いします。</li>
            <li><span className="num">5.</span>施設内への荷物の持ち込みは最小限にしてください。ゴミはビニール袋に密封して持ち帰ってください。</li>
          </ul>
        </div>
        
        <p className="mark-txt">
          <span className="mark">（２）</span>
          熱中症などの健康被害を避けるため、衣服などで体温調整し、水分を補給するなどの対策を十分に行ってください。
        </p>
        
        <h3>感染が疑われる方が発生した場合</h3>
        <p className="mark-txt">
          <span className="mark">（１）</span>
          見学中や見学後に、新型コロナウイルス感染症の感染が疑われる方が発生した場合は、見学代表者の方は、直ちに理研職員（見学担当者）にお伝えください。
        </p>
        <div className="mark-txt-content">
          <DataList>
            <DataListRow title="連絡先">029-836-9111</DataListRow>
          </DataList>
        </div>
        <p className="mark-txt">
          <span className="mark">（２）</span>
          見学者が新型コロナウイルス感染症への感染が明らかになった場合は、保健所等の指示により見学時に提出いただいた「[別紙]理化学研究所バイオリソース研究センター施設見学者名簿」を保健所等に提出させていただく場合があります。
        </p>
        <p className="mark-txt">
          <span className="mark">（３）</span>
          状況により、一定の期間、見学休止となる場合があります。
        </p>

      </ContentLayout>
    </Layout>
  )
}
export default Page


export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
    edges {
      node {
        base
        name
        extension
        prettySize
      }
    }
  }
}`

  